import 'core-js/fn/object/assign'
import domready from 'domready'
import xhr from 'xhr'
import notifier from '../vendor/notifier'

let block = false

function getCheckedRadioValue (name) {
  let elements = document.getElementsByName(name)
  console.log(name, elements)
  for (let i = 0, len = elements.length; i < len; ++i) {
    if (elements[i].checked) return elements[i].value
  }
}

function nextStep (number) {
  let _progressbar = document.getElementById('progressbar')
  let proAkt = parseFloat(_progressbar.style.width.replace('%', ''))
  let proDiff = parseFloat(_progressbar.getAttribute('data-porzent'))
  let _steps = document.getElementsByClassName('step')
  let _stepOut
  if (_steps.length) {
    for (let i = _steps.length - 1; i >= 0; i--) {
      if (!_steps[i].classList.contains('step-out') && !_steps[i].classList.contains('step-in')) {
        _stepOut = _steps[i]
      }
    }
  }
  let progess = proAkt + proDiff
  _stepOut.classList.add('step-out')
  document.getElementById('step-' + number).classList.remove('step-in')
  _progressbar.style.width = progess + '%'
}

function backStep (number) {
  let _progressbar = document.getElementById('progressbar')
  let proAkt = parseFloat(_progressbar.style.width.replace('%', ''))
  let proDiff = parseFloat(_progressbar.getAttribute('data-porzent'))
  let _steps = document.getElementsByClassName('step')
  let _stepOut
  if (_steps.length) {
    for (let i = _steps.length - 1; i >= 0; i--) {
      if (!_steps[i].classList.contains('step-in') && !_steps[i].classList.contains('step-out')) {
        _stepOut = _steps[i]
      }
    }
  }
  let progess = proAkt - proDiff
  _stepOut.classList.add('step-in')
  document.getElementById('step-' + number).classList.remove('step-out')
  _progressbar.style.width = progess + '%'
}

function buttonNext () {
  let _buttonNext = document.getElementsByClassName('next-step')
  if (_buttonNext.length) {
    for (let i = _buttonNext.length - 1; i >= 0; i--) {
      _buttonNext[i].removeEventListener('click', function () {})
      _buttonNext[i].addEventListener('click', function () {
        if (block === false) {
          block = true
          setTimeout(function () {
            block = false
          }, 500)
          let stepID = this.getAttribute('data-aktStep')
          let iCount = this.getAttribute('data-i')
          let artID = getCheckedRadioValue('step-' + iCount)
          sentStep(artID, stepID)
          nextStep(this.getAttribute('data-step'))
        }
      })
    }
  }
}

function buttonBack () {
  let _buttonNext = document.getElementsByClassName('back-step')
  if (_buttonNext.length) {
    for (let i = _buttonNext.length - 1; i >= 0; i--) {
      _buttonNext[i].removeEventListener('click', function () {})
      _buttonNext[i].addEventListener('click', function () {
        if (block === false) {
          block = true
          setTimeout(function () {
            block = false
          }, 500)
          let stepID = this.getAttribute('data-aktStep')
          let iCount = this.getAttribute('data-i')
          let artID = getCheckedRadioValue('step-' + iCount)
          sentStep(artID, stepID)
          backStep(this.getAttribute('data-step'))
        }
      })
    }
  }
}

function sentStep (artID, stepID) {
  let data = {
    modus: 'setStep',
    artID: artID,
    stepID: stepID
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err)
    }
    let s = JSON.parse(body)
    console.log(s)
    document.getElementById('insert-wk').innerHTML = s.body
  })
}

function sentContactData (step) {
  let data = {
    modus: 'sentContactData',
    anFirma: document.getElementById('anFirma').value,
    anName: document.getElementById('anName').value,
    anStreet: document.getElementById('anStreet').value,
    anPLZ: document.getElementById('anPLZ').value,
    anCity: document.getElementById('anCity').value,
    anWebsite: document.getElementById('anWebsite').value,
    anMail: document.getElementById('anMail').value,
    anTelefon: document.getElementById('anTelefon').value,
    anZahlungsweise: getCheckedRadioValue('anZahlungsweise')
  }
  let elementDefault = [
    'anFirma',
    'anName',
    'anStreet',
    'anPLZ',
    'anCity',
    'anWebsite',
    'anMail',
    'anTelefon'
  ]
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err)
    }
    let s = JSON.parse(body)
    console.log(s)
    if (s.status === 'success') {
      document.getElementById('anfrage-daten').innerHTML = s.body
      nextStep(step)
    } else {
      notifier.show(s.messageTitel, s.message, s.status, '', 4000)
      for (let i = elementDefault.length - 1; i >= 0; i--) {
        if (document.getElementById(elementDefault[i]).classList.contains('has-error')) {
          document.getElementById(elementDefault[i]).classList.remove('has-error')
        }
      }
      if (s.elements.length > 0) {
        for (let i = s.elements.length - 1; i >= 0; i--) {
          document.getElementById(s.elements[i]).classList.add('has-error')
        }
      }
    }
  })
}

function sentAngebot (step) {
  let datenschutz = document.getElementById('Datenschutzhinweis')
  let agb = document.getElementById('AGB')
  if (!datenschutz.checked) {
    notifier.show('Datenschutzhinweis', 'Bitte bestätigen Sie den Datenschutz!', 'danger', '', 4000)
  } else if (!agb.checked) {
    notifier.show('AGB', 'Bitte bestätigen Sie die AGB´s!', 'danger', '', 4000)
  } else {
    let data = {
      modus: 'sentAngebot'
    }
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err)
      }
      let s = JSON.parse(body)
      console.log(s)
      if (s.status === 'success') {
        document.getElementById('wk-abschluss').innerHTML = s.body
        nextStep(step)
      } else {
        notifier.show(s.messageTitel, s.message, s.status, '', 4000)
      }
    })
  }
}

function getInputs () {
  let _inputs = document.getElementsByClassName('step-input')
  if (_inputs.length) {
    for (let i = _inputs.length - 1; i >= 0; i--) {
      _inputs[i].removeEventListener('change', function () {})
      _inputs[i].addEventListener('change', function () {
        let artID = this.value
        let stepID = this.getAttribute('data-step')
        sentStep(artID, stepID)
      })
    }
  }
}

function initSteps () {
  buttonNext()
  buttonBack()
  getInputs()
  if (document.getElementById('sendContactData')) {
    document.getElementById('sendContactData').addEventListener('click', function () {
      sentContactData(this.getAttribute('data-step'))
    })
  }
  if (document.getElementById('paket-buchen')) {
    document.getElementById('paket-buchen').addEventListener('click', function () {
      sentAngebot(this.getAttribute('data-step'))
    })
  }
}

export default function init () {
  domready(() => {
    let ate = document.getElementsByClassName('step')
    if (ate !== null) {
      initSteps()
    }
  })
}
