/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import selectAll from './modules/select-all'
import baguetteBox from 'baguettebox.js'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import debug from './modules/debug'
import steps from './modules/anfrage'
import formulare from './modules/formulare'
import Swiper from 'swiper'
import xhr from 'xhr'
import jump from 'jump.js'

/**
 * Ab geht die Reise
 */
domready(() => {
  initToggleSubMenu()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  steps()
  formulare()
  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })
  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 5000,
    speed: 2000,
    loop: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
  })
// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    pagination: '.leistungen-slider-pagination',
    slidesPerView: 2,
    slidesPerColumn: 2,
    paginationClickable: true,
    spaceBetween: 0,
    autoplay: 5000,
    speed: 2000,
    loop: true,
    breakpoints: {
      // when window width is <= 320px
      768: {
        slidesPerView: 1,
        slidesPerColumn: 1
      },
      1024: {
        slidesPerView: 1,
        slidesPerColumn: 2
      }
    }
  })

  if (window.location.hash) {
    jump(window.location.hash, {
      offset: -200
    })
  }
  let forms = selectAll('.open-meinung')
  forms.map(form => form.addEventListener('click', function (event) {
    event.preventDefault()
    this.classList.add('hidden')
    document.getElementById(this.getAttribute('data-id')).classList.remove('hidden')
    return false
  }))

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line
  debug()

  function statistik () {
    xhr({
      body: JSON.stringify({
        version: this.getAttribute('data-version'),
        userAgent: navigator.userAgent,
        modus: 'savsStatistik'
      }),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err)
      }
    })
  }

  if (document.getElementById('savestatistik')) {
    document.getElementById('savestatistik').addEventListener('click', statistik)
  }
})
